<!-- 库存管理-详情 -->
<template>
  <div class="pagePadding">
    <div class="top">
      <Form inline class="formMarginBtm20 clearfix">
        <!-- 产品名称 -->
        <FormItem>
          <span>产品名称：</span>
          <Select filterable placeholder="请选择" v-model="queryFrom.product_id" clearable class="iviewIptWidth250" @on-change="productChange">
            <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
          </Select>
        </FormItem>
        <!-- 规格型号 -->
        <FormItem class="marginLeft40">
          <span>规格型号/SKU：</span>
          <Select placeholder="请选择" v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0" clearable class="iviewIptWidth250" :disabled="!queryFrom.product_id" multiple filterable>
            <Option v-for="item in modelList" :value="item.specification_id" :key="item.specification_id + item.product_id" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
          </Select>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>批号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.batch_number"></i-input>
        </FormItem>
        <!-- 有效期 -->
        <FormItem class="marginLeft40">
          <span>有效期：</span>
          <DatePicker :editable="false" type="daterange" class="iviewIptWidth250 marginRight60" :value="listDate" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime"></DatePicker>
        </FormItem>
        <!-- 按钮 -->
        <FormItem class="po-create-number marginRight0" :label-width="20">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnAdd marginLeft20" @click="upload">导出</span>
        </FormItem>
      </Form>
      <div class="name">
        <div class="fl">{{ name }}</div>
        <div class="fr pageBtn finger btnSure marginLeft20" @click="showSetupModal">设置</div>
        <span class="pageBtn finger btnReset fr" @click="back">返回</span>
      </div>
    </div>
    <div class="pageTop" ref="pageTop">
      <Table :rowClass="rowClassName" @on-sort-change="sort" :productList="listColumns" :productData="listData" :option_page="option_page" border @change-page="changePage" @sortChange="sortChange" :isLoad="isLoad" :loading="isLoad"> </Table>
    </div>
    <div class="pageBtm clearfix">
      <div class="summary" v-if="listData.length > 0">
        总数量：<span class="color389">{{ totalNum }}</span> 合计金额：<span class="color389">¥{{ totalMoney }}</span
        >元
      </div>
      <div class="tabPages" v-if="listData.length > 0 && total">
        <Page :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
        <div class="fr" style="font-size: 14px;">共计{{ total }}条记录</div>
      </div>
    </div>
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" :footer-hide="true" class-name="vertical-center-modal" title="导出维度">
      <div v-if="chooseStatus">
        <RadioGroup v-model="animal">
          <Radio label="1">
            <span>产品维度(产品编号)</span>
          </Radio>
          <Radio label="2">
            <span>规格维度(批号)</span>
          </Radio>
        </RadioGroup>
        <div class="clearfix" style="margin-top: 20px;">
          <span class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'detailsInventoryManage',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      animal: '1',
      chooseStatus: false,
      tableHeight: 0,
      option_page: '01',
      setupStatus: false,
      // 加载中
      isLoad: true,
      // 分页
      total: 1,
      // 表格数据
      listData: [],
      listColumns: [],
      columns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 60,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          resizable: true,
        },
        {
          title: '注册证/备案凭证号',
          key: 'licence_code',
          align: 'center',
          resizable: true,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          resizable: true,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          resizable: true,
        },
        {
          title: '条形码',
          key: 'bar_code',
          align: 'center',
          resizable: true,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          resizable: true,
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
          resizable: true,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          resizable: true,
        },
        {
          title: '存储条件',
          key: 'storage_condition',
          align: 'center',
          resizable: true,
        },
        {
          title: '数量',
          key: 'inventory_quantity',
          align: 'center',
          sortable: true,
          resizable: true,
        },
        {
          title: '生产日期',
          key: 'productionDate',
          align: 'center',
          sortable: true,
          resizable: true,
        },
        {
          title: '有效期',
          key: 'validPeriod',
          align: 'center',
          sortable: true,
          resizable: true,
        },
      ],
      productList: [],
      modelList: [],
      // 查询参数
      queryFrom: {
        id: '',
        page: 1,
        rows: 10,
        product_id: '',
        product_code_array: [],
        batch_number: '',
        start_valid_period: '',
        end_valid_period: '',
        quantity_sort: '',
        production_date_sort: '',
        valid_period_sort: '',
        create_time: '',
      },
      name: '',
      listDate: [],
      titleList: [],
      export_type: '',
    }
  },
  mounted() {
    this.queryOption()
    let from = JSON.parse(this.$route.query.queryFrom)
    this.queryFrom.id = this.$route.query.id || from.warehouse_id
    this.$set(this.queryFrom, 'product_code_array', from.product_code_array)
    this.queryFrom.batch_number = from.batch_number
    this.queryFrom.start_valid_period = from.start_valid_period
    this.queryFrom.end_valid_period = from.end_valid_period
    this.queryFrom.product_id = from.product_id
    this.queryFrom.create_time = from.create_time
    this.queryFrom.supplier_id = from.supplier_id
    this.queryFrom.factory_id = from.factory_id
    this.name = this.$route.query.name
    this.listDate = [from.start_valid_period, from.end_valid_period]
    this.queryProductList()
    this.$nextTick(() => {
      // 页面渲染完成后的回调
      this.tableHeight = this.$refs.pageTop.offsetHeight + ''
    })
  },
  methods: {
    // 有效期小于180标黄
    rowClassName(row, index) {
      if (row.warning) return 'orginRow'
      return ''
    },
    sort(data) {
      console.log(data)
      this.queryFrom.sort_str = data.key
      this.queryFrom.sort_type = data.order
      this.queryDataList()
    },
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page }, false)
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          // 添加排序功能
          this.titleList.forEach((item, index) => {
            if (item.title === '产品编号') {
              item.sortable = 'custom'
            }
            if (item.title === '有效期') {
              item.sortable = 'custom'
            }
            if (item.title === '近效期天数') {
              item.sortable = 'custom'
            }
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
          console.log(this.listColumns)
        })
        .then(() => {
          this.queryDataList()
        })
    },
    showSetupModal() {
      this.setupStatus = true
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryDataList()
    },
    // 查询列表数据
    queryDataList() {
      this.isLoad = true
      this.queryFrom.specification_id = this.queryFrom.product_code_array.join(',')
      this.$http.get(this.$api.warehouseManagementDetail, this.queryFrom, true).then(res => {
        this.listData = res.data
        this.total = res.total
        this.totalNum = res.total_number
        this.totalMoney = res.total_amount
        this.isLoad = false
        let list = ['production_license_valid_period', 'record_valid_period', 'business_license_valid_period', 'production_record_date', 'licence_valid_period', 'create_time', 'insert_time', 'purchase_time', 'receive_time', 'accept_time', 'rec_time']
        for (let i = 0; i < this.listData.length; i++) {
          for (let j = 0; j < list.length; j++) {
            if (this.listData[i][list[j]]) {
              this.listData[i][list[j]] = this.listData[i][list[j]] ? this.$moment(this.listData[i][list[j]] * 1000).format('YYYY-MM-DD') : ''
            }
          }
          // 生产日期和有效期转换
          this.listData[i].production_date = this.listData[i].production_date ? this.$moment(this.listData[i].production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          // 判断是录入的是年月还是年月日
          this.listData[i].production_date = this.listData[i].production_date ? this.$utils.Timetransformation(this.listData[i].production_date) : ''
          this.listData[i].warning = this.$utils.warningDay(this.listData[i].valid_period, 180)
          this.listData[i].valid_period = this.listData[i].valid_period ? this.$moment(this.listData[i].valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          // 判断是录入的是年月还是年月日
          this.listData[i].valid_period = this.listData[i].valid_period ? this.$utils.Timetransformation(this.listData[i].valid_period) : ''
        }
      })
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.start_valid_period = null
        this.queryFrom.end_valid_period = null
      } else {
        this.queryFrom.start_valid_period = e[0]
        this.queryFrom.end_valid_period = e[1]
      }
    },
    // 产品改变
    productChange(e) {
      this.queryFrom.product_code_array = []
      if (!e) return
      this.modelList = []
      this.querySKU(e)
    },
    // 查询SKU
    querySKU(e) {
      this.$http.get(this.$api.inventoryCodeLookup, { product_id: e, warehouse_id: this.queryFrom.id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 查询仓库列表
    queryProductList() {
      this.$http.get(this.$api.warehouseManagementProduct, { id: this.queryFrom.id }, true).then(res => {
        this.productList = res.data
        if (this.queryFrom.product_id) {
          this.querySKU(this.queryFrom.product_id)
        }
      })
    },
    // 排序搜索
    sortChange(row, key, order) {
      if (row.key == 'quantity') {
        this.queryFrom.quantity_sort = row.order
      } else if (row.key == 'productionDate') {
        this.queryFrom.production_date_sort = row.order
      } else {
        this.queryFrom.valid_period_sort = row.order
      }
      this.queryDataList()
    },
    // 分页
    changePage(e) {
      this.queryFrom.page = e
      this.queryDataList()
    },
    upload() {
      this.chooseStatus = true
    },
    // 确认导出
    sureChoose() {
      this.queryFrom.specification_id = this.queryFrom.product_code_array.join(',') + ''
      // 获取产品名称
      let productName = ''
      this.productList.forEach(item => {
        if (item.product_id == this.queryFrom.product_id) {
          productName = item.product_name
        }
      })
      let obj = {
        warehouse_id: this.queryFrom.id,
        product_name: productName,
        specification_id: this.queryFrom.specification_id,
        batch_number: this.queryFrom.batch_number,
        start_valid_period: this.queryFrom.start_valid_period,
        end_valid_period: this.queryFrom.end_valid_period,
        export_type: this.animal,
        create_time: this.queryFrom.create_time,
        factory_id: this.queryFrom.factory_id,
        supplier_id: this.queryFrom.supplier_id,
      }
      this.$http.downFile(this.$api.getWarehouseDetailExport, obj, true).then(res => {
        this.$Message.success('导出成功')
        this.chooseStatus = false
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '仓库存导出表.xlsx'
        aLink.click()
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="less">
.tabPages {
  margin-top: 10px;
  overflow: hidden;
  line-height: 32px;
}
.name {
  font-size: 16px;
  margin-bottom: 18px;
  color: #333;
  height: 36px;
  line-height: 36px;
  div:nth-child(1) {
    font-weight: bold;
  }
}
.pagePadding {
  display: flex;
  flex-direction: column;
  // overflow: hidden !important;
  .summary > span:first-child {
    margin-right: 30px;
  }
}
.pageTop {
  flex: 1;
  // padding-bottom: 30px;
}
.pageBtm {
  height: 95px;
}
</style>
